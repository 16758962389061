import React from 'react';

const CashPhone: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C5.73478 2 5.48043 2.10536 5.29289 2.29289C5.10536 2.48043 5 2.73478 5 3V21C5 21.2652 5.10536 21.5196 5.29289 21.7071C5.48043 21.8946 5.73478 22 6 22H18C18.2652 22 18.5196 21.8946 18.7071 21.7071C18.8946 21.5196 19 21.2652 19 21V3C19 2.73478 18.8946 2.48043 18.7071 2.29289C18.5196 2.10536 18.2652 2 18 2H6ZM3.87868 0.87868C4.44129 0.316071 5.20435 0 6 0H18C18.7956 0 19.5587 0.316071 20.1213 0.87868C20.6839 1.44129 21 2.20435 21 3V21C21 21.7957 20.6839 22.5587 20.1213 23.1213C19.5587 23.6839 18.7957 24 18 24H6C5.20435 24 4.44129 23.6839 3.87868 23.1213C3.31607 22.5587 3 21.7957 3 21V3C3 2.20435 3.31607 1.44129 3.87868 0.87868Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M13 4V20H11V4H13Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3322 5.49306C12.9743 5.37538 14.8534 5.79118 15.8567 6.34266L16.7331 6.82435L15.7697 8.57704L14.8933 8.09535C14.2757 7.75583 12.7913 7.39363 11.4751 7.48795C10.8254 7.53451 10.3422 7.68505 10.0474 7.89289C9.80622 8.06288 9.64804 8.29339 9.64804 8.732C9.64804 9.28335 9.85963 9.60954 10.2525 9.89759C10.713 10.2353 11.3702 10.4762 12.2062 10.7548C12.2647 10.7743 12.3244 10.7941 12.3851 10.8142C13.1117 11.0548 13.9785 11.3418 14.6606 11.7814C15.4704 12.3032 16.132 13.1117 16.132 14.351C16.132 15.3986 15.7814 16.2674 15.115 16.8974C14.4752 17.5024 13.6379 17.799 12.8089 17.9235C11.1661 18.1704 9.2061 17.7962 7.66174 17.241L6.7207 16.9027L7.3973 15.0207L8.33834 15.359C9.71198 15.8528 11.318 16.1251 12.5117 15.9457C13.101 15.8572 13.4995 15.6725 13.741 15.4441C13.9559 15.2409 14.132 14.9244 14.132 14.351C14.132 13.9693 13.9832 13.7241 13.5772 13.4626C13.1175 13.1663 12.4826 12.9549 11.6653 12.6827C11.6351 12.6726 11.6046 12.6624 11.5739 12.6522C10.7889 12.3906 9.82504 12.0642 9.06985 11.5105C8.24695 10.9071 7.64804 10.0177 7.64804 8.732C7.64804 7.63612 8.12811 6.79887 8.895 6.25825C9.60821 5.75546 10.5028 5.5525 11.3322 5.49306Z"
      />
    </svg>
  );
};

export default CashPhone;
